<template lang="vi">
<div>
    <page-header :title="`Chỉnh sửa thông tin`" :items="breadcrumbs" />
    <div v-if="!loading">
        <b-row>
            <b-col lg="12">
                <b-card>
                    <b-form-group>
                        <label>Tiêu đề <span class="red">*</span></label>
                        <b-form-textarea v-model="form.ten" id="textarea-default" class="form-control-sm" style="font-size: 1rem" rows="1" placeholder="Nhập tiêu đề"></b-form-textarea>
                    </b-form-group>
                    <b-form-group>
                        <label>Lĩnh vực <span class="red">*</span></label>
                        <b-form-select v-model="form.linhVuc" aria-placeholder="a">
                            <option v-for="(cat) in categories" :key="cat" :value="cat">
                                {{ cat }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group>
                        <label>Nội dung <span class="red">*</span></label>
                        <quill-editor v-model="form.noiDung" :content="content" :options="editorOption" @change="onEditorChange($event)" />
                    </b-form-group>
                    <b-form-group>
                        <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                            {{addErrorMessage}}
                        </div>
                        <b-button variant="success" class="mr-2 mt-3" @click.prevent="create">
                            Lưu
                        </b-button>
                        <b-button variant="danger" class="mt-3" @click.prevent="toListView">
                            Huỷ
                        </b-button>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import PageHeader from "../../../components/page-header";
import Multiselect from "vue-multiselect";
import {
    httpClient
} from "../../../_utils/httpClient";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import {
    quillEditor
} from "vue-quill-editor";
import _ from "lodash";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/vi";
import Swal from "sweetalert2";

export default {
    name: "create",
    components: {
        PageHeader,
        Multiselect,
        quillEditor,
        DatePicker,
    },
    data: function () {
        return {
            categories: [],
            content: "",
            editorOption: {},
            breadcrumbs: [{
                text: "Trang chủ",
                to: "/admin/home",
            },
            {
                text: "Thông tin",
                to: "/admin/informations",
            },
            {
                text: "Chỉnh sửa",
                to: "#",
                active: true,
            },
            ],
            options: [{
                text: 'Có',
                value: true
            }, {
                text: 'Không',
                value: false
            }],
            showAddErrorMessage: false,
            addErrorMessage: "",
            form: {
                id: '',
                ten: "",
                linhVuc: "",
                noiDung: "",
                guiThongBao: false,
            },
            loading: false,
        };
    },
    watch: {},
    methods: {
        onEditorChange(event) {
            this.form.noiDung = event.html;
        },
        async toListView() {
            await this.$router.push({
                name: "admin.informations.list",
            });
        },
        validator() {
            let mappingErrorName = {
                ten: 'Tiêu đề',
                linhVuc: 'Lĩnh vực',
                noiDung: 'Nội dung',
                guiThongBao: 'Gửi thông báo',
            }
            for (let key in this.form) {
                if (key == "noiDung") {
                    if (this.form[key].replace('<p>', '').replace('</p>', '').trim() == "") {
                        this.showAddErrorMessage = true
                        this.addErrorMessage = mappingErrorName[key] + ` không được trống.`
                        return false
                    }
                }
                if (this.form[key] == null || this.form[key].length < 1) {
                    this.showAddErrorMessage = true
                    this.addErrorMessage = mappingErrorName[key] + ` không được trống.`
                    return false
                }
            }
            return true;
        },
        async create() {
            await Swal.queue([{
                title: "Chỉnh sửa thông tin này",
                confirmButtonText: "Lưu",
                cancelButtonText: "Hủy bỏ",
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                showCancelButton: true,
                showconfirmButton: true,
                preConfirm: async () => {
                    this.showAddErrorMessage = false;
                    this.form.ten = this.form.ten.trim()
                    this.form.noiDung = this.form.noiDung.trim()
                    if (!this.validator()) return;
                    let payload = {
                        ...this.form,
                    };
                    let response = await httpClient.post(`decision`, payload);
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: "Lưu thành công",
                        }),
                            await this.$router.push({
                                name: "admin.informations.list",
                            });
                    } else {
                        this.showAddErrorMessage = true;
                        this.addErrorMessage = response.data.message;
                    }
                    return true;
                },
            },]);
        },
        async getCategories() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: "linhvucquyetdinh",
                },
            });
            this.categories = response.data.data.map((el) => {
                return el.name;
            });
        },
        async getById(id) {
            let response = await httpClient.get(`decision`, {
                params: {
                    id,
                },
            });
            this.form = response.data.data;
        },
    },
    async created() {
        await this.getCategories();
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            await this.getById(this.$route.params.id);
        } else {
            for (let key in this.form) {
                this.form[key] = cloneItem[key]
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}
</style><style>
.red {
    color: red;
}
.ql-container {
    min-height: 300px;
    max-height: 500px;
    overflow: auto;
}
</style>
